<template>
  <div class="important-event-box">
    <div class="important-event-header">
      <div class="important-event-header-title">
<!--        重大事件-->
         {{$t('label.service.major.event')}}
      </div>
      <div class="important-event-header-text">
        {{ $t('label.service.major.event.desc') }}
      </div>
    </div>
<!--    编辑或新建重大事件-->
    <div class="important-event-operation">
      <div class="important-event-operation-t">
        <div class="new-event-l">
          <span v-show="!viewMode">
            {{ isEditMode === 'true'? $t('label.service.major.event.edit') : $t('label.service.major.event.new') }}
          </span>
        </div>
        <div class="new-event-m">
          <el-button @click="saveImEvent(0)" :disabled="viewMode" size="mini">
            {{ $t('label.chatter.save') }}
          </el-button>
          <el-button @click="saveImEvent(1)" v-if="isEditMode === 'false'" size="mini">
            {{ $t('label.saveandnew') }}
          </el-button>
          <el-button @click="cancelEdit" size="mini">
            {{ $t('label.emailsync.button.cancel') }}
          </el-button>
        </div>
<!--        必填信息-->
        <div class="new-event-r"><span style="color: red">*</span>{{ $t('label.email.track.required') }}</div>
      </div>
      <div class="important-event-operation-c">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item :label="$t('label.chatter.name')" prop="name">
            <el-input style="width: 50%" :disabled="viewMode" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.recordtype.remark')" >
            <el-input type="textarea" :disabled="viewMode" autosize v-model="ruleForm.desc"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.service.cycle.type')" prop="cycleType">
            <el-select style="width: 50%" v-model="ruleForm.cycleType" :placeholder="$t('label.service.cycle.type')" :disabled="viewMode">
              <el-option :label=" $t('label.service.major.event.independent') " value="1"></el-option>
              <el-option :label="$t('label.service.major.event.oreder')" value="2"></el-option>
              <el-option :label="$t('label.service.without.cycle')" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="important-event-operation-b">
        <div class="new-event-m">
          <el-button @click="saveImEvent(0)" :disabled="viewMode" size="mini">
            {{ $t('label.chatter.save') }}
          </el-button>
          <el-button @click="saveImEvent(1)" v-if="isEditMode === 'false'" size="mini">
            {{ $t('label.saveandnew') }}
          </el-button>
          <el-button @click="cancelEdit" size="mini">
            {{ $t('label.emailsync.button.cancel') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as majorEventApi from "./api.js";

export default {
  name: "editImportantEvent",
  data() {
    return {
      isEditMode: this.$route.query.isEditMode, // 判断是否为编辑模式
      isDetail: this.$route.query.isDetail, // 判断是否为编辑模式
      id: this.$route.query.id || '', // 判断是否为编辑模式
      ruleForm: {
        name: '',
        cycleType: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: this.$i18n.t('vue_label_homepage_enter_name'), trigger: 'blur' },
          { min: 1, max: 255, message: this.$i18n.t('label.ccchat.person.invite.toolong'), trigger: 'blur' }
        ],
        cycleType: [
          { required: true, message: this.$i18n.t('label.service.cycle.type'), trigger: 'change' }
        ]
      }, // 校验规则
    }
  },
  computed: {
    viewMode() {
      if (this.isDetail === "true") {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    // 编辑
    editImEvent() {
      majorEventApi.getMajoreventTypeDetail({id: this.id}).then(res=>{
        if (res.result) {
          this.ruleForm = {
            name: res.data.name,
            cycleType: res.data.type,
            desc: res.data.description
          }
        }
      })
    },
    // 保存、新建
    saveImEvent(flag) {
      switch (flag) {
        case 0:
          this.submitForm('ruleForm', 0)
          break;
        case 1:
          this.submitForm('ruleForm', 1)
          break;
      }
    },
    // 表单提交校验
    
    submitForm(formName, flag) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // name	String	重大事件类型名称
          // type	String	重大事件类型 1：独立，2：xu:序列，3：无周期
          // description	String	描述
          let options = {
            id: this.id || '',
            name: this.ruleForm.name,
            type: this.ruleForm.cycleType,
            description: this.ruleForm.desc
          }
          majorEventApi.saveMajoreventType(options).then(res=>{
            if (res.result) {
              this.$message.success(this.$i18n.t('savesuccess'))
              if (flag === 0) {
                this.cancelEdit()
              } else {
                this.$refs[formName].resetFields();
                this.ruleForm = {
                  name: '',
                  cycleType: '',
                  desc: ''
                }
              }
            }
          })
        } else {
          
          return false;
        }
      });
    },
    // 重置表单
    
    resetForm() {
      this.ruleForm = {
        name: '',
        cycleType: '',
        desc: ''
      }
    },
    // 取消编辑
    cancelEdit() {
      this.$router.go(-1)
    },

    initPage() {
      if (this.$route.query.isEditMode === 'true') {
        this.editImEvent()
      }
    }
  },
  mounted() {
    this.initPage()
  },
  filters: {
    typeStr(str) {
      let tempFlag
      switch (str) {
        case '1':
          tempFlag = this.$i18n.t('label.service.major.event.independent')
          break;
        case '2':
          tempFlag = this.$i18n.t('label.service.major.event.oreder')
          break;
        case '3':
          tempFlag = this.$i18n.t('label.service.without.cycle')
          break;
      }
      return tempFlag
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item{
  margin-bottom: 0;
}
.important-event-box{
  padding: 18px 10px;
  box-sizing: border-box;
  .important-event-header{
    color: #333333;
    &-title{
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    &-text{
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
  .important-event-table{
    .important-event-container{
      background: #F5F5F5;
      border: 1px solid #DCDCDC;
      border-radius: 3px;
      width: 100%;
      padding: 0 10px 10px;
      box-sizing: border-box;
      &-option{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 60px;
      }
      &-table{
        border: 1px solid #DCDCDC;
        border-radius: 3px;
        .im-event-item{
          color: #006DCC;
          cursor: pointer;
          text-decoration:underline;
        }
      }
    }
    .bottom_page {
      height: 40px;
      padding: 0 15px;
      background: #ffffff;
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .page-option {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .page-option-l {
          display: flex;
          align-items: center;
        }
        .page-option-m {
          display: flex;
          .el-button {
            border: none;
            font-size: 12px;
            // &:hover {
            //   background: none;
            // }
          }
        }
        .page-option-r {
          display: flex;
          align-items: center;
          input {
            width: 32px;
            height: 20px;
            outline: none;
            border: 1px solid #ccc;
            padding: 0 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .skip-page-btn{
            margin: 0 5px;
            height: 22px;
            padding: 0 5px;
            border: 1px solid #ccc;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .important-event-operation{
    background: #F5F5F5;
    border: 1px solid #DCDCDC;
    border-radius: 3px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    &-t{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      .new-event-l, .new-event-r{
        width: 15%;
        font-size: 16px;
      }
      .new-event-l{
        color: #333333;
      }
      .new-event-r{
        text-align: right;
        color: #151515;
        span{
          color: #C23934;
        }
      }
      .new-event-m{
      }
    }
    &-c{
      padding: 16px 190px 30px;
      border: 1px solid #DCDCDC{
        left: 0;
        right: 0;
      };
    }
    &-b{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
}
</style>
